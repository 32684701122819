<template>
  <div class="wrapper">
    <el-dialog title="数据导入" :visible.sync="dialogImport" width="width">
      <div class="import-file">
        <div>
          <el-form ref="form" label-width="100px">
            <el-form-item label="数据类型：" prop="" :rules="rules">
              <el-select
                  @change="changeDataType"
                  v-model="dataType"
                  size="mini"
                  placeholder="请选择"
              >
                <el-option label="设备状态" :value="1"> </el-option>
                <el-option label="售后状态" :value="2"> </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="文件选择：" prop="" :rules="rules">
              <div><el-button size="small" type="primary" @click="selectFile">上传文件</el-button></div>
              <div class="upload1">
                <el-upload
                    ref="upload"
                    class="upload-demo"
                    drag
                    action="#"
                    accept=".xlsx,.xls"
                    :limit="1"
                    :file-list="fileList"
                    :http-request="uploadExcel"
                    :on-remove="handleRemove"
                >
                  <i class="el-icon-upload"></i>
                  <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                </el-upload>
              </div>
            </el-form-item>
            <el-form-item label="模板下载：" prop="">
              <template v-if="dataType == 1">
                <span style="margin-right: 10px;">设备状态模板</span>
                <el-button
                    size="small"
                    type="primary"
                    @click="downloadTemplate"
                >下载</el-button>
              </template>
              <template v-if="dataType == 2">
                <span style="margin-right: 10px;">售后状态模板</span>
                <el-button
                    size="small"
                    type="primary"
                    @click="downloadTemplate"
                >下载</el-button>
              </template>
            </el-form-item>
          </el-form>
        </div>
        <div class="submit">
          <div class="left"></div>
          <div class="right">
            <el-button size="small" type="" @click="dialogImport = false">取消</el-button>
            <el-button size="small" type="primary" @click="startUpload">上传</el-button>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { importOrderExcelAPI } from './api'

export default {
  name: 'DataImport',
  data() {
    return {
      dialogImport: false,
      rules: [{ required: true, message: '该项不能为空', trigger: 'blur' }],
      dataType: 1,
      file: null,
      fileList: [],
      formData: null,
    }
  },
  mounted() {},
  methods: {
    downloadTemplate() {
      let a = document.createElement('a')
      a.href = this.dataType == 1 ? '/static/设备状态.xlsx' : '/static/售后状态.xlsx';
      a.download = this.dataType == 1 ? '设备状态.xlsx' : '售后状态.xlsx';
      a.style.display = 'none';
      document.body.appendChild(a);
      a.click();
      a.remove();
    },
    changeDataType(dataType) {
      this.dataType = dataType;
    },
    selectFile() {
      this.$refs['upload'].$refs['upload-inner'].handleClick();
    },
    uploadExcel(param) {
      this.file = param.file;
    },
    startUpload() {
      if (!this.file) {
        return this.$message.error('请选择文件');
      }
      const formData = new FormData();
      formData.append('file', this.file);
      formData.append('dataType', this.dataType);
      importOrderExcelAPI(formData).then(() => {
        this.$message.success('文件已上传成功');
        this.$emit('getList');
        this.dialogImport = false;
      })
    },
    init() {
      this.dataType = 1;
      this.file = null;
      this.fileList = [];
      this.$refs.upload.clearFiles();
    },
    handleRemove() {
      this.file = null;
      this.fileList = [];
      this.$refs.upload.clearFiles();
    },
  }
}
</script>

<style lang="scss" scoped>
.wrapper /deep/ {
  .import-file {
    padding: 0 30px;
    .upload {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
      .left {
        .tip {
          font-size: 14px;
          margin-left: 20px;
          color: #999;
        }
      }
    }
    .upload1 {
      .el-upload {
        width: 100%;
        .el-upload-dragger {
          width: 100%;
        }
      }
    }
    .submit {
      display: flex;
      justify-content: space-between;
      margin-top: 10px;
    }
  }
}
</style>
