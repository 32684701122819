<template>
	<div class="wrapper">
		<admin-title :title="$route.meta.name"></admin-title>
		<div class="header-search">
			<el-form ref="form" inline :model="searchData" label-width="90px">
				<el-form-item label="关键字" class="kw">
					<el-input
						placeholder="请输入"
						v-model="searchData.keywordValue"
						class="input-with-select"
						size="small"
					>
						<el-select
							v-model="searchData.keywordName"
							slot="prepend"
							placeholder="请选择"
							size="small"
						>
							<el-option
								v-for="item in searchTypeOrder"
								:key="item.value"
								:label="item.name"
								:value="item.value"
							></el-option>
						</el-select>
					</el-input>
				</el-form-item>
				<el-form-item label="下单时间">
					<el-date-picker
						style="width: 300px"
						v-model="searchData.timeSlot"
						size="small"
						type="daterange"
						value-format="yyyy-MM-dd"
						range-separator="至"
						start-placeholder="开始日期"
						end-placeholder="结束日期"
					>
					</el-date-picker>
				</el-form-item>
				<el-form-item label="门店" class="store">
					<SelectStore v-model="searchData.storeName"></SelectStore>
				</el-form-item>
				<el-form-item label="用户类型">
					<el-select
						v-model="searchData.memberType"
						placeholder="请选择"
						size="small"
						clearable
					>
						<el-option
							v-for="item in memberType"
							:key="item.value"
							:label="item.name"
							:value="item.value"
						></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="订单类型">
					<el-select
						v-model="searchData.orderType"
						placeholder="请选择"
						size="small"
						clearable
					>
						<el-option
							v-for="item in dict_store_order_type"
							:key="item.value"
							:label="item.name"
							:value="item.value"
						></el-option>
					</el-select>
				</el-form-item>
				<!-- <el-form-item label="是否征信" v-if="searchData.activeStatus !== '0'">
					<el-select
						v-model="searchData.enableCredit"
						placeholder="请选择"
						size="small"
						clearable
					>
						<el-option
							v-for="item in credit_is_checked"
							:key="item.value"
							:label="item.name"
							:value="item.value"
						>
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="征信状态" v-if="searchData.activeStatus !== '0'">
					<el-select
						v-model="searchData.creditQueryStatus"
						placeholder="请选择"
						size="small"
						clearable
					>
						<el-option
							v-for="item in creditQueryStatus"
							:key="item.value"
							:label="item.name"
							:value="item.value"
						>
						</el-option>
					</el-select>
				</el-form-item> -->
				<el-form-item label="是否公证">
					<el-select
						v-model="searchData.enableNotary"
						placeholder="请选择"
						size="small"
						clearable
					>
						<el-option
							v-for="item in notary_is_checked"
							:key="item.value"
							:label="item.name"
							:value="item.value"
						>
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="公证状态">
					<el-select
						v-model="searchData.notaryPhxStatus"
						placeholder="请选择"
						size="small"
						clearable
					>
						<el-option
							v-for="item in notaryStatus"
							:key="item.value"
							:label="item.name"
							:value="item.value"
						>
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="是否免押">
					<el-select
						v-model="searchData.enableAlipayFundAuth"
						placeholder="请选择"
						size="small"
						clearable
					>
						<el-option label="是" :value="true"> </el-option>
						<el-option label="否" :value="false"> </el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="售后状态">
					<SelectDict
						v-model="searchData.afterSaleStatus"
						width="180px"
						dictCode="order:afterSaleStatus"
					></SelectDict>
				</el-form-item>
				<el-form-item label="设备状态">
					<el-select
						v-model="searchData.deviceStatus"
						placeholder="请选择"
						size="small"
						clearable
					>
						<el-option
							v-for="item in dict_device_status"
							:key="item.value"
							:label="item.name"
							:value="item.value"
						>
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="锁机状态">
					<el-select
						v-model="searchData.lockStatus"
						placeholder="请选择"
						size="small"
						clearable
					>
						<el-option label="未锁机" :value="0"> </el-option>
						<el-option label="待锁机" :value="1"> </el-option>
						<el-option label="已锁机" :value="2"> </el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="待锁机日期">
					<el-date-picker
						style="width: 300px"
						v-model="searchData.timeSlotLock"
						size="small"
						type="daterange"
						value-format="yyyy-MM-dd"
						range-separator="至"
						start-placeholder="开始日期"
						end-placeholder="结束日期"
					>
					</el-date-picker>
				</el-form-item>
				<el-form-item v-show="searchData.activeStatus == '0'" label="是否成交">
					<el-select
						v-model="searchData.enablePayDeal"
						placeholder="请选择"
						size="small"
						clearable
					>
						<el-option label="是" :value="true"> </el-option>
						<el-option label="否" :value="false"> </el-option>
					</el-select>
				</el-form-item>
				<el-form-item label=" " label-width="50px">
					<el-button
						type="primary"
						size="small"
						icon="el-icon-search"
						@click="getList()"
						>搜索</el-button
					>
					<el-button
						type="info"
						size="small"
						icon="el-icon-refresh"
						@click="reset"
						>重置</el-button
					>
				</el-form-item>
			</el-form>
		</div>
		<!-- <el-divider></el-divider> -->
		<div class="header-tab">
			<div class="left-tab">
				<span
					@click="searchData.activeStatus = tab.value"
					v-for="tab in orderStatus"
					:key="tab.value"
				>
					<el-badge
						v-if="tab.value != 0"
						:class="[searchData.activeStatus == tab.value ? 'select-tab' : '']"
						:value="getStatic(tab.value)"
					>
						{{ tab.name }}
					</el-badge>
					<el-badge
						v-else
						:class="[searchData.activeStatus == tab.value ? 'select-tab' : '']"
					>
						{{ tab.name }}
					</el-badge>
				</span>
			</div>

			<div>
				<el-button
					v-checkbtn="isShowBtn(AUTH_BTN.order_list_data_import)"
					type="success"
					size="small"
					icon="el-icon-upload2"
					@click="handleDataUpload"
					>数据导入</el-button
				>
				<el-button
					v-checkbtn="isShowBtn(AUTH_BTN.order_list_export)"
					type="primary"
					size="small"
					icon="el-icon-download"
					@click="downloadExcel"
					>导出</el-button
				>
			</div>
		</div>

		<tp-table
			@handleSelectionChange="handleSelectionChange"
			:isCheckBox="false"
			:tableData="list"
			:columns="columns"
			:totalNum="total"
			:current-page.sync="currentPage"
			:pageSize.sync="pageSize"
		/>
		<!-- 公证 -->
		<el-dialog title="公证链接" :visible.sync="dialogVisible" width="800px">
			<div class="link">
				<span>公证链接：</span>
				<span>{{ notaryLink }}</span>
				<span>
					<el-button type="primary" size="mini" @click="copyLink"
						>复制</el-button
					>
				</span>
			</div>
		</el-dialog>
		<!-- 线下支付 -->
		<el-dialog
			title="请确认首付"
			:visible.sync="dialogOfflinePay"
			width="800px"
		>
			<div class="prepay">
				<!-- 账期 -->
				<el-descriptions v-if="prePayInfo.prepayList">
					<el-descriptions-item
						v-for="(item, i) in prePayInfo.prepayList.slice(0, -2)"
						:key="i"
						:label="item.name"
						>{{ item.amount }} 元</el-descriptions-item
					>
				</el-descriptions>
				<el-divider></el-divider>
				<el-descriptions v-if="prePayInfo.prepayList" :column="1">
					<el-descriptions-item
						v-for="(item, i) in prePayInfo.prepayList.slice(-2)"
						:key="i"
						:label="item.name"
						>{{ item.amount }} 元</el-descriptions-item
					>
					<el-descriptions-item
						:labelStyle="{ fontSize: '18px', fontWeight: 'bold' }"
						:contentStyle="{ fontSize: '18px', fontWeight: 'bold' }"
						label="首付金额"
						>{{ prePayInfo.prepayAmount }} 元</el-descriptions-item
					>
				</el-descriptions>
			</div>
			<div slot="footer">
				<el-button @click="dialogOfflinePay = false" size="small"
					>取消</el-button
				>
				<el-button type="primary" @click="submitOfflinePay" size="small"
					>确定</el-button
				>
			</div>
		</el-dialog>
		<!-- 卡密查看 -->
		<el-dialog
			:title="kamiOrderInfo.orderNo"
			:visible.sync="dialogKami"
			width="800px"
		>
			<el-divider content-position="center">卡密详情</el-divider>
			<div class="kami">
				<div class="member-name" v-if="kamiInfo.member">
					<div class="item">
						客户姓名：<span>{{ kamiInfo.member.memberName }}</span>
					</div>
					<div class="item">
						手机号：<span>{{ kamiInfo.member.mobile }}</span>
					</div>
				</div>
				<div class="list">
					<div class="item" v-for="(item, i) in kamiInfo.jdCardList" :key="i">
						<p>卡号{{ i + 1 }}：{{ item.cardNo }}</p>
						<p>卡密{{ i + 1 }}：{{ item.cardSecret }}</p>
					</div>
				</div>
			</div>
		</el-dialog>
		<!-- 售后状态管理 -->
		<el-dialog
			title="售后状态管理"
			:visible.sync="dialogAfterSaleStatus"
			class="after-sale-dialog"
			width="800px"
		>
			<el-form
				ref="formAfterSaleStatus"
				:model="afterSaleStatusInfo"
				label-width="130px"
			>
				<el-form-item label="订单号：">{{
					afterSaleStatusInfo.orderNo
				}}</el-form-item>
				<el-form-item label="用户姓名：">{{
					afterSaleStatusInfo.memberName
				}}</el-form-item>
				<el-form-item label="售后当前状态：">{{
					afterSaleStatusInfo.afterSaleStatusName
				}}</el-form-item>
				<el-form-item label="售后状态更新：">
					<SelectDict
						v-model="afterSaleStatusInfo.afterSaleStatus"
						width="240px"
						dictCode="order:afterSaleStatus"
					></SelectDict>
				</el-form-item>
			</el-form>
			<div slot="footer" style="margin-top: 20px">
				<el-button @click="dialogAfterSaleStatus = false" size="mini"
					>取消</el-button
				>
				<el-button type="primary" @click="submitAfterSaleStatus" size="mini"
					>提交</el-button
				>
			</div>
		</el-dialog>
		<!-- 设备状态管理 -->
		<el-dialog
			title="售后状态管理"
			:visible.sync="dialogDeviceStatus"
			class="after-sale-dialog"
			width="800px"
		>
			<el-form
				ref="formDeviceStatus"
				:model="deviceStatusInfo"
				label-width="130px"
			>
				<el-form-item label="订单号：">{{
					deviceStatusInfo.orderNo
				}}</el-form-item>
				<el-form-item label="用户姓名：">{{
					deviceStatusInfo.memberName
				}}</el-form-item>
				<el-form-item label="设备当前状态：">{{
					deviceStatusInfo.deviceStatusName
				}}</el-form-item>
				<el-form-item label="设备状态更新：">
					<el-select
						v-model="deviceStatusInfo.deviceStatus"
						placeholder="请选择"
						size="small"
						clearable
					>
						<el-option
							v-for="item in dict_device_status"
							:key="item.value"
							:label="item.name"
							:value="item.value"
						>
						</el-option>
					</el-select>
				</el-form-item>
			</el-form>
			<div slot="footer" style="margin-top: 20px">
				<el-button @click="dialogDeviceStatus = false" size="mini"
					>取消</el-button
				>
				<el-button type="primary" @click="submitDeviceStatus" size="mini"
					>提交</el-button
				>
			</div>
		</el-dialog>
		<!-- 设备状态管理 -->
		<el-dialog
			title="线下签收"
			:visible.sync="dialogConfirmReceive"
			class="after-sale-dialog"
			width="800px"
		>
			<el-form
				ref="formConfirmReceive"
				:model="receiveInfo"
				label-width="130px"
			>
				<el-form-item label="订单号：">{{ receiveInfo.orderNo }}</el-form-item>
				<el-form-item label="签收单上传" prop="receiptImage" :rules="rules">
					<FileUpload
						:name.sync="receiveInfo.receiptImageList"
						:is-multi="true"
					/>
				</el-form-item>
			</el-form>
			<div slot="footer" style="margin-top: 20px">
				<el-button @click="dialogConfirmReceive = false" size="mini"
					>取消</el-button
				>
				<el-button type="primary" @click="submitConfirmReceive" size="mini"
					>确认签收</el-button
				>
			</div>
		</el-dialog>
		<!-- 数据导入 -->
		<DataImport ref="dataImport" @getList="getList"></DataImport>
    <!-- 公证流程 -->
    <NotaryProcess @finish="getList" :visible.sync="notary.visible" :orderNo="notary.orderNo" :notaryStatus="notary.notaryPhxStatus" />
		<div style="height: 50px"></div>
	</div>
</template>

<script>
import SelectDict from "@/views/components/select-dict.vue";
import { authBtnMixin } from "@/mixins/authBtnMixin";
import {
	getListAPI,
	handleOrderCancelAPI,
	downloadOrderContractAPI,
	getOrderStaticAPI,
	DownloadAPI,
	downFile,
	getNotaryApplyAPI,
	getNotaryFileAPI,
	handleCompleteAPI,
	handleAheadReturnAPI,
	getOfflinePayDetailAPI,
	submitOfflinePayAPI,
	getKamiDetailAPI,
	hitAPI,
	getAfterSaleStatusAPI,
	submitAfterSaleStatusAPI,
	getDeviceStatusAPI,
	submitDeviceStatusAPI,
	submitConfirmReceiveAPI,
} from "./api";
import {
	searchTypeOrder,
	orderStatus,
	memberType,
	credit_is_checked,
	notary_is_checked,
	creditQueryStatus,
	notaryStatus,
	dict_store_order_type,
	dict_device_status,
    enumNotaryStatus
} from "@/enum/dict.js";
import { dateFormat } from "@/utils/index.js";
import SelectStore from "@/views/components/select-store.vue";
import { submitLockAPI } from "@/views/finance/count/api";
import FileUpload from "@/components/file-upload/index.vue";
import DataImport from "./data-import.vue";
import NotaryProcess from './modules/notary-process.vue'

const columns = [
	{
		label: "用户信息",
		prop: "member",
		minWidth: "180",
		customRender(h, row) {
			return (
				<div>
					<div>姓名：{row["member"]?.["memberName"]}</div>
					<div>手机号：{row["member"]?.["mobile"]}</div>
				</div>
			);
		},
	},
	{
		label: "门店/渠道",
		prop: "StoreChannel",
		minWidth: "200",
		customRender(h, row) {
			return (
				<div>
					<div v-show={row["store"]}>
						<div>门店名称：{row["store"]?.["storeName"]}</div>
						<div>联系电话：{row["store"]?.["legalMobile"]}</div>
						<div>门店归属：{row["store"]?.["businessUserName"]}</div>
					</div>
					<div v-show={row["channel"]}>
						<div>渠道名称：{row["channel"]?.["channelName"]}</div>
						<div>渠道归属：{row["channel"]?.["chargeName"]}</div>
					</div>
				</div>
			);
		},
	},
	{
		label: "审核人",
		prop: "reviewUser",
		minWidth: "100",
		customRender(h, row) {
			return <p>{row["reviewUser"]?.["userName"]}</p>;
		},
	},
	// {
	//   label: '客服',
	//   prop: 'kfUser',
	//   minWidth: '100',
	//   customRender(h, row) {
	//     return <p>{row['kfUser']?.['userName']}</p>
	//   }
	// },
	{
		label: "订单信息",
		prop: "createTime",
		minWidth: "300",
		customRender(h, row) {
			return (
				<div>
					<div>订单单号：{row["orderNo"]}</div>
					<div>下单时间：{row["createTime"]}</div>
					<div>订单状态：{row["statusName"]}</div>
					<div>
						订单类型：
						<el-tag v-show={row["orderType"] == 1} type="primary" size="mini">
							普通订单
						</el-tag>
						<el-tag v-show={row["orderType"] == 2} type="warning" size="mini">
							门店订单
						</el-tag>
						<el-tag v-show={row["orderType"] == 3} type="success" size="mini">
							卡券订单
						</el-tag>
						<el-tag
							v-show={row["orderType"] == 4}
							color="#ffcccc54"
							style="color:#ff7744;border-color:#ffcccc54"
							size="mini"
						>
							电车订单
						</el-tag>
						<el-tag
							v-show={row["enableAlipayFundAuth"] == true}
							type="danger"
							size="mini"
							style="margin-left: 5px"
						>
							免押
						</el-tag>
					</div>

					<div>下单方式：{row["clientTypeName"]}</div>

					<div>
						套餐类型：
						<el-tag type="primary" size="mini">
							{row["goodsComboName"]}
						</el-tag>
					</div>

					<div>售后状态：{row["afterSaleStatusName"]}</div>
				</div>
			);
		},
	},
	{
		label: "商品信息",
		prop: "orderGoods",
		minWidth: "200",
		customRender(h, row) {
			return (
				<div>
					<div>
						{row["orderGoods"]?.["skuTitle"]}
						<el-tag
							type={
								row["orderGoods"]?.["goodsPurity"] == "fresh"
									? "primary"
									: "warning"
							}
							size="mini"
							style="margin-left:2px;"
						>
							{row["orderGoods"]?.["goodsPurityName"]}
						</el-tag>
					</div>
					<div>设备状态：{row["deviceStatusName"]}</div>
					<div>
						锁机状态：
						<el-tag v-show={row["lockStatus"] == 0} type="success" size="mini">
							未锁机
						</el-tag>
						<el-tag v-show={row["lockStatus"] == 1} type="warning" size="mini">
							待锁机
						</el-tag>
						<el-tag v-show={row["lockStatus"] == 2} type="danger" size="mini">
							已锁机
						</el-tag>
					</div>
					<div>
						待锁机日期：
						{row["lockDate"] || "无"}
					</div>
				</div>
			);
		},
	},
	{
		label: "价格",
		prop: "rentPrice",
		minWidth: "220",
		align: "center",
		customRender(h, row) {
			return (
				<div>
					<div class="price" v-show={row["goodsCombo"] == "buyoutReturnAtTerm"}>
						<div class="item">
							<span>总租金(期数)：</span>
							<span>
								{row["rentPrice"]} ({row["billPeriodNum"]}期)
							</span>
						</div>
						<div class="item">
							<span>买断金：</span>
							<span> {row["buyoutPrice"]}</span>
						</div>
						<div class="item">
							<span>溢价金：</span>
							<span>{row["overflowPrice"]}</span>
						</div>
						<div class="item">
							<span>保险金：</span>
							<span> {row["insurancePrice"]}</span>
						</div>
						<div class="item">
							<span>平台服务费：</span>
							<span> {row["servicePrice"]}</span>
						</div>
					</div>
					<div class="price" v-show={row["goodsCombo"] == "payAfterUse"}>
						<div class="item">
							<span>金额：</span>
							<span>{row["rentPrice"]}</span>
						</div>
						<div class="item">
							<span>后付天数：</span>
							<span> {row["delayDayNum"]}</span>
						</div>
					</div>
					<div class="price" v-show={row["goodsCombo"] == "payAtOnce"}>
						<div class="item">
							<span>金额：</span>
							<span>{row["rentPrice"]}</span>
						</div>
					</div>
				</div>
			);
		},
	},
	{
		label: "时间",
		prop: "payTime",
		minWidth: "250",
		customRender(h, row) {
			return (
				<div>
					<div>支付时间：{row["payTime"]}</div>
					<div>开始时间：{row["startRentDate"]}</div>
					<div>结束时间：{row["endRentDate"]}</div>
				</div>
			);
		},
	},
	{
		label: "发货信息",
		prop: "orderAddress",
		minWidth: "250",
		customRender(h, row) {
			return (
				<div>
					<div>
						收货信息：{row["orderAddress"]?.["contactName"]}{" "}
						{row["orderAddress"]?.["contactMobile"]}
					</div>
					<div>
						收货地址：{row["orderAddress"]?.["province"]}
						{row["orderAddress"]?.["city"]}
						{row["orderAddress"]?.["district"]}
						{row["orderAddress"]?.["detail"]}
					</div>
				</div>
			);
		},
	},
	{
		label: "商户",
		prop: "merchant",
		minWidth: "150",
		customRender(h, row) {
			return <p>{row["merchant"]?.["merchantName"]}</p>;
		},
	},
	{
		label: "公证信息",
		prop: "createTime",
		minWidth: "150",
		customRender(h, row) {
			return (
				<div>
					<div>公证：{row["enableNotary"] ? "是" : "否"}</div>
					<div>公证状态：{this.getNotaryStatus(row["notaryPhxStatus"])}</div>
				</div>
			);
		},
	},
	{
		label: "操作",
		minWidth: "130",
		fixed: "right",
		customRender(h, row) {
			return (
				<div>
					<el-link
						disabled={!this.isShowBtn(this.AUTH_BTN.order_list_detail)}
						style="margin-right:10px;"
						underline={false}
						type="primary"
						onClick={() => {
							let link = this.$router.resolve({
								name: `OrderDetail`,
								params: { id: row["orderNo"] },
								query: {
									memberId: row["member"]?.["id"],
									orderType: row["orderType"],
								},
							});
							window.open(link.href, "_blank");
						}}
					>
						详情
					</el-link>

					<el-link
						disabled={
							!this.isShowBtn(this.AUTH_BTN.order_list_send) ||
							!row["enableMaterialDelivery"] ||
							!row.storeSettleAmount
						}
						style="margin-right:10px;"
						v-show={
							this.searchData.activeStatus == 4 &&
							(row["deliveryTypeList"].indexOf(1) > -1 ||
								row["deliveryTypeList"].indexOf(2) > -1)
						}
						underline={false}
						type="primary"
						onClick={() => this.handleSend(row, 1)}
					>
						实物发货
					</el-link>
					<el-link
						disabled={
							!this.isShowBtn(this.AUTH_BTN.order_list_send) ||
							!row["enableVirtualDelivery"]
						}
						style="margin-right:10px;"
						v-show={
							this.searchData.activeStatus == 4 &&
							row["deliveryTypeList"].indexOf(3) > -1
						}
						underline={false}
						type="primary"
						onClick={() => this.handleSend(row, 2)}
					>
						虚拟发货
					</el-link>

					<el-link
						disabled={!this.isShowBtn(this.AUTH_BTN.order_list_bill)}
						v-show={
							(row["status"] >= 3 &&
								row["status"] <= 9 &&
								this.searchData.activeStatus != 0) ||
							row["status"] == 91
						}
						style="margin-right:10px;"
						underline={false}
						type="primary"
						onClick={() => {
							let link = this.$router.resolve({
								name: `OrderBill`,
								params: { id: row["orderNo"] },
							});
							window.open(link.href, "_blank");
						}}
					>
						账单
					</el-link>

					<el-link
						disabled={!this.isShowBtn(this.AUTH_BTN.order_list_cancel)}
						style="margin-right:10px;"
						v-show={
							!this.isShowBtn(this.AUTH_BTN.order_list_cancel_high) &&
							row["status"] < 2 &&
							this.searchData.activeStatus != 0
						}
						underline={false}
						type="primary"
						onClick={() => this.handleOrderCancel(row["orderNo"])}
					>
						取消
					</el-link>

					<el-link
						disabled={!this.isShowBtn(this.AUTH_BTN.order_list_cancel_high)}
						style="margin-right:10px;"
						v-show={
							this.isShowBtn(this.AUTH_BTN.order_list_cancel_high) &&
							this.searchData.activeStatus < 8
						}
						underline={false}
						type="primary"
						onClick={() => this.handleOrderCancel(row["orderNo"])}
					>
						取消(高)
					</el-link>

					<el-link
						disabled={!this.isShowBtn(this.AUTH_BTN.order_list_hetong)}
						style="margin-right:10px;"
						v-show={
							(row["status"] >= 4 &&
								row["status"] <= 9 &&
								this.searchData.activeStatus != 0) ||
							row["status"] == 91
						}
						underline={false}
						type="primary"
						onClick={() =>
							this.$router.push({
								name: "OrderContractDetail",
								params: { id: row["orderNo"] },
							})
						}
					>
						合同凭证
					</el-link>

          <el-link
						disabled={!this.isShowBtn(this.AUTH_BTN.order_list_notary_phx)}
						style="margin-right:10px;"
						v-show={
							[enumNotaryStatus.WAITING_FOR_LINK, enumNotaryStatus.WAITING_FOR_SIGN, enumNotaryStatus.WAITING_FOR_AUDIT].includes(row.notaryPhxStatus) && this.searchData.activeStatus == 4
						}
						underline={false}
						type="primary"
            onClick={() => {this.openNotaryProcess(row)}}
					>
						公证流程
					</el-link>

					<el-link
						disabled={!this.isShowBtn(this.AUTH_BTN.order_list_complete)}
						style="margin-right:10px;"
						v-show={this.searchData.activeStatus == 8}
						underline={false}
						type="primary"
						onClick={() => this.handleComplete(row)}
					>
						完成
					</el-link>

					<el-link
						disabled={!this.isShowBtn(this.AUTH_BTN.order_list_ahead_return)}
						style="margin-right:10px;"
						v-show={row["status"] == 6 || row["status"] == 7}
						underline={false}
						type="primary"
						onClick={() => this.handleAheadReturn(row)}
					>
						提前归还
					</el-link>

					<el-link
						disabled={!this.isShowBtn(this.AUTH_BTN.order_list_offline_pay)}
						style="margin-right:10px;"
						v-show={
							this.searchData.activeStatus == 2 &&
							row["goodsCombo"] !== "payAtOnce"
						}
						underline={false}
						type="primary"
						onClick={() => this.handleOfflinePay(row)}
					>
						线下支付
					</el-link>

					<el-link
						disabled={!this.isShowBtn(this.AUTH_BTN.order_list_kami)}
						v-show={
							row["orderType"] == 3 &&
							(this.searchData.activeStatus == 4 ||
								this.searchData.activeStatus == 5 ||
								this.searchData.activeStatus == 6 ||
								this.searchData.activeStatus == 7 ||
								this.searchData.activeStatus == 91)
						}
						underline={false}
						type="primary"
						onClick={() => this.handleKami(row)}
					>
						卡密查看
					</el-link>

					<el-link
						disabled={
							!this.isShowBtn(this.AUTH_BTN.order_list_after_sale_status)
						}
						style="margin-right:10px;"
						v-show={this.searchData.activeStatus == 7}
						underline={false}
						type="primary"
						onClick={() => this.handleAfterSaleStatus(row["orderNo"])}
					>
						售后状态
					</el-link>

					<el-link
						disabled={!this.isShowBtn(this.AUTH_BTN.order_list_device_status)}
						style="margin-right:10px;"
						v-show={
							this.searchData.activeStatus >= 5 &&
							this.searchData.activeStatus <= 8
						}
						underline={false}
						type="primary"
						onClick={() => this.handleDeviceStatus(row["orderNo"])}
					>
						设备状态
					</el-link>

					<el-link
						disabled={!this.isShowBtn(this.AUTH_BTN.order_list_confirm_receive)}
						style="margin-right:10px;"
						v-show={this.searchData.activeStatus == 5}
						underline={false}
						type="primary"
						onClick={() => this.handleConfirmReceive(row["orderNo"])}
					>
						线下签收
					</el-link>
				</div>
			);
		},
	},
];
export default {
	name: "OrderList",
	mixins: [authBtnMixin],
	components: { FileUpload, SelectStore, SelectDict, DataImport, NotaryProcess },
	data() {
		return {
			dialogKami: false,
			dialogOfflinePay: false,
			dialogVisible: false,
			dialogAfterSaleStatus: false,
			dialogDeviceStatus: false,
			dialogConfirmReceive: false,
			columns,
			currentPage: 1,
			pageSize: 10,
			total: 0,
			list: [],
			searchData: {
				keywordName: "",
				keywordValue: "",
				timeSlot: [],
				leftCreateDate: "",
				rightCreateDate: "",
				activeStatus: "0",
				memberType: "",
				enableNotary: "",
                notaryPhxStatus: null,
				// enableCredit: "",
				// creditQueryStatus: "",
				orderType: "",
				storeName: "",
				goodsCombo: "",
				deliveryStatus: "",
				enableAlipayFundAuth: "",
				afterSaleStatus: "",
				deviceStatus: "",
				lockStatus: "",
				timeSlotLock: [],
				enablePayDeal: "",
			},
			// 字典
			searchTypeOrder,
			orderStatus,
			credit_is_checked,
			notary_is_checked,
			// creditQueryStatus,
			notaryStatus,
			dict_device_status,
			dict_store_order_type,
			// 已勾选
			tableSelected: [],
			staticData: {},
			memberType,
			notaryLink: "",
			// 其他
			isFirstEnter: true,
			// 首付明细
			prePayInfo: {},
			prePayOrderNo: "",
			// 卡密信息
			kamiInfo: {},
			kamiOrderInfo: {
				id: "",
				orderNo: "",
			},
			comboList: [],
			afterSaleStatusInfo: {
				orderNo: "",
				memberName: "",
				afterSaleStatus: "",
				afterSaleStatusName: "",
			},
			deviceStatusInfo: {
				orderNo: "",
				memberName: "",
				deviceStatus: "",
				deviceStatusName: "",
			},
			receiveInfo: {
				orderNo: "",
				receiptImageList: [],
			},
			rules: [{ required: true, message: "该项不能为空", trigger: "blur" }],
            notary: {
                visible: false,
                orderNo: null,
                notaryStatus: null
            }
		};
	},
	provide() {
		return {
			context: this,
		};
	},
	created() {
		this.isFirstEnter = true;
	},
	beforeRouteEnter(to, from, next) {
		next();
	},
	activated() {
		this.getList();
	},
	mounted() {
		window["getBpageList"] = () => {
			this.getList();
		};
	},
	watch: {
		currentPage() {
			this.getList();
		},
		pageSize() {
			this.getList();
		},
		"searchData.activeStatus": {
			// immediate: true,
			handler(newV, oldV) {
				this.getList();
			},
		},
	},
	methods: {
		async getList() {
			const {
				timeSlot,
				keywordName,
				keywordValue,
				activeStatus,
				memberType,
				enableNotary,
				notaryPhxStatus,
				orderType,
				storeName,
				goodsCombo,
				deliveryStatus,
				enableAlipayFundAuth,
				afterSaleStatus,
				deviceStatus,
				lockStatus,
				timeSlotLock,
				enablePayDeal,
			} = this.searchData;

            console.log(notaryPhxStatus)
			let params = {
				page: this.currentPage,
				pageSize: this.pageSize,
				memberType,
				enableNotary,
				notaryPhxStatus,
				orderType,
				storeName,
				goodsCombo,
				deliveryStatus,
				enableAlipayFundAuth,
				afterSaleStatus,
				deviceStatus,
				lockStatus,
				enablePayDeal,
			};
			if (activeStatus != 0) params.status = activeStatus - 0;
			if (timeSlot && timeSlot.length > 0) {
				params.leftCreateDate = timeSlot[0];
				params.rightCreateDate = timeSlot[1];
			}
			if (timeSlotLock && timeSlotLock.length > 0) {
				params.leftLockDate = timeSlotLock[0];
				params.rightLockDate = timeSlotLock[1];
			}
			if (keywordName) params[keywordName] = keywordValue;
			const res = await getListAPI(params);
			this.list = res.list || [];
			this.total = res.total || 0;
			this.getOrderStatic();
		},
		handleSelectionChange(val) {
			this.tableSelected = val;
		},
		reset() {
			let act = false;
			if (this.searchData.activeStatus == "0") {
				act = true;
			}
			Object.assign(this._data.searchData, this.$options.data().searchData);
			if (act) {
				this.getList();
			}
		},
		// 取消订单
		handleOrderCancel(orderNo) {
			this.$confirm("此操作将永久取消该订单, 是否继续?", "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning",
			})
				.then(() => {
					handleOrderCancelAPI(orderNo).then(() => {
						this.getList();
						this.$message({
							type: "success",
							message: "操作成功!",
						});
					});
				})
				.catch(() => {
					this.$message({
						type: "info",
						message: "已取消操作",
					});
				});
		},
		// 下载合同
		downloadOrderContract(orderNo) {
			this.$confirm("此操作将下载该订单合同, 是否继续?", "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning",
			})
				.then(() => {
					downloadOrderContractAPI(orderNo).then((res) => {
						const name = `${orderNo}_${dateFormat()}.pdf`;
						downFile(res.downloadUrl, name);
						// downloadPDF(res.downloadUrl, name)
					});
				})
				.catch(() => {
					this.$message({
						type: "info",
						message: "已取消操作",
					});
				});
		},
		async getOrderStatic() {
			this.staticData = await getOrderStaticAPI();
		},
		getStatic(value) {
			const { staticData } = this;
			let num = 0;
			switch (value) {
				case "1":
					num = staticData.inReviewNum || 0;
					break;
				case "2":
					num = staticData.waitPayNum || 0;
					break;
				case "3":
					num = staticData.waitContractNum || 0;
					break;
				case "4":
					num = staticData.waitDeliveryNum || 0;
					break;
				case "5":
					num = staticData.waitReceiveNum || 0;
					break;
				case "6":
					num = staticData.inRentNum || 0;
					break;
				case "7":
					num = staticData.inOverdueNum || 0;
					break;
				case "8":
					num = staticData.waitBuyoutNum || 0;
					break;
				case "9":
					num = staticData.waitReturnNum || 0;
					break;
				case "91":
					num = staticData.completeNum || 0;
					break;
				case "92":
					num = staticData.cancelNum || 0;
					break;
				case "93":
					num = staticData.refuseNum || 0;
					break;
				default:
					break;
			}
			return num;
		},
		handleDataUpload() {
			this.$refs.dataImport.dialogImport = true;
			this.$nextTick(() => {
				this.$refs.dataImport.init();
			});
		},
		// 导出
		downloadExcel() {
			const {
				timeSlot,
				keywordName,
				keywordValue,
				activeStatus,
				memberType,
				enableCredit,
				enableNotary,
				notaryPhxStatus,
				orderType,
				storeName,
				goodsCombo,
				deliveryStatus,
				enableAlipayFundAuth,
				afterSaleStatus,
				deviceStatus,
				lockStatus,
				timeSlotLock,
				enablePayDeal,
			} = this.searchData;
			let params = {
				memberType,
				enableCredit,
				enableNotary,
				notaryPhxStatus,
				orderType,
				storeName,
				goodsCombo,
				deliveryStatus,
				enableAlipayFundAuth,
				afterSaleStatus,
				deviceStatus,
				lockStatus,
				enablePayDeal,
			};
			if (activeStatus != 0) params.status = activeStatus - 0;
			if (timeSlot && timeSlot.length > 0) {
				params.leftCreateDate = timeSlot[0];
				params.rightCreateDate = timeSlot[1];
			}
			if (timeSlotLock && timeSlotLock.length > 0) {
				params.leftLockDate = timeSlotLock[0];
				params.rightLockDate = timeSlotLock[1];
			}
			if (keywordName) params[keywordName] = keywordValue;
			DownloadAPI(params);
		},
		// 公证链接
		handleGetLink(orderNo) {
			getNotaryApplyAPI(orderNo).then((res) => {
				this.notaryLink = res.applyUrl;
				this.dialogVisible = true;
			});
		},
		copyLink() {
			this.$copyText(this.notaryLink).then(
				() => {
					this.$message.success("复制成功");
				},
				() => {
					this.$message.error("复制失败");
				}
			);
		},
		// 公证证书
		handleGetOrderCert(orderNo) {
			this.$confirm("此操作将下载该订单公证证书, 是否继续?", "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning",
			})
				.then(() => {
					getNotaryFileAPI(orderNo).then((res) => {
						const name = `${orderNo}_${dateFormat()}.pdf`;
						downFile(res.fileUrl, name);
					});
				})
				.catch(() => {
					this.$message({
						type: "info",
						message: "已取消操作",
					});
				});
		},
		// getCreditStatus(status) {
		// 	const res = this.creditQueryStatus.find((item) => status == item.value);
		// 	return (res && res.name) || "";
		// },
		getNotaryStatus(status) {
			const res = this.notaryStatus.find((item) => status == item.value);
			return (res && res.name) || "";
		},
		// 发货按钮
		async handleSend(
			{
				deliveryTypeList,
				orderNo,
				enableMaterialDelivery,
				enableVirtualDelivery,
			},
			type
		) {
			const { rosterLevelList } = await hitAPI(orderNo);
			localStorage.setItem(
				"deliveryTypeList",
				JSON.stringify(deliveryTypeList)
			);
			localStorage.setItem(
				"enableMaterialDelivery",
				JSON.stringify(enableMaterialDelivery)
			);
			localStorage.setItem(
				"enableVirtualDelivery",
				JSON.stringify(enableVirtualDelivery)
			);
			localStorage.setItem("btn_type", type);
			localStorage.setItem("rosterLevelList", JSON.stringify(rosterLevelList));
			let link = this.$router.resolve({
				name: `OrderDelivery`,
				params: { id: orderNo },
			});
			window.open(link.href, "_blank");
			// if (orderType == 1) {
			//   let link = this.$router.resolve({ name: `OrderDelivery`, params: { id: orderNo } })
			//   window.open(link.href, '_blank')
			// } else if (orderType == 2) {
			//   let link = this.$router.resolve({ name: `OrderDeliveryStore`, params: { id: orderNo } })
			//   window.open(link.href, '_blank')
			// }
		},
		// 完成
		handleComplete({ orderNo }) {
			this.$confirm("确认要操作已完成吗？", "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning",
			})
				.then(() => {
					handleCompleteAPI({ orderNo }).then(() => {
						this.$message.success("操作成功");
						this.getList();
					});
				})
				.catch(() => {
					this.$message({
						type: "info",
						message: "已取消操作",
					});
				});
		},
		// 提前归还
		handleAheadReturn({ orderNo }) {
			this.$confirm("确认要操作提前完成吗？", "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning",
			})
				.then(() => {
					handleAheadReturnAPI({ orderNo }).then(() => {
						this.$message.success("操作成功");
						this.getList();
					});
				})
				.catch(() => {
					this.$message({
						type: "info",
						message: "已取消操作",
					});
				});
		},
		// 线下支付
		handleOfflinePay({ orderNo }) {
			this.prePayOrderNo = orderNo;
			getOfflinePayDetailAPI(orderNo).then((res) => {
				this.prePayInfo = res;
				this.dialogOfflinePay = true;
			});
		},
		// 确认首付
		submitOfflinePay() {
			submitOfflinePayAPI(this.prePayOrderNo).then(() => {
				this.$message.success("首付成功");
				this.getList();
				this.dialogOfflinePay = false;
			});
		},
		// 卡密查看
		async handleKami({ id, orderNo }) {
			this.kamiOrderInfo = { id, orderNo };
			const res = await getKamiDetailAPI(orderNo);
			this.kamiInfo = res;
			this.dialogKami = true;
		},
		handleAfterSaleStatus(orderNo) {
			getAfterSaleStatusAPI(orderNo).then((res) => {
				this.afterSaleStatusInfo = res;
				this.dialogAfterSaleStatus = true;
			});
		},
		submitAfterSaleStatus() {
			let data = {
				orderNo: this.afterSaleStatusInfo.orderNo,
				afterSaleStatus: this.afterSaleStatusInfo.afterSaleStatus,
			};
			submitAfterSaleStatusAPI(data).then(() => {
				this.$message.success("操作成功");
				this.getList();
				this.dialogAfterSaleStatus = false;
			});
		},
		handleDeviceStatus(orderNo) {
			getDeviceStatusAPI(orderNo).then((res) => {
				this.deviceStatusInfo = res;
				if (res.deviceStatus == 0) {
					this.deviceStatusInfo.deviceStatus = null;
				}
				this.dialogDeviceStatus = true;
			});
		},
		submitDeviceStatus() {
			let data = {
				orderNo: this.deviceStatusInfo.orderNo,
				deviceStatus: this.deviceStatusInfo.deviceStatus,
			};
			submitDeviceStatusAPI(data).then(() => {
				this.$message.success("操作成功");
				this.getList();
				this.dialogDeviceStatus = false;
			});
		},
		handleConfirmReceive(orderNo) {
			this.receiveInfo.orderNo = orderNo;
			this.receiveInfo.receiptImageList = [];
			this.dialogConfirmReceive = true;
		},
		submitConfirmReceive() {
			submitConfirmReceiveAPI(this.receiveInfo).then(() => {
				this.$message.success("操作成功");
				this.getList();
				this.dialogConfirmReceive = false;
			});
		},
    openNotaryProcess(row) {
      this.notary = {
          orderNo: row.orderNo,
          visible: true,
          notaryStatus: row.notaryPhxStatus
      }
    }
	},
};
</script>

<style lang="scss" scoped>
.wrapper /deep/ {
	width: 100%;
	.header-search {
		margin-bottom: 20px;
		.el-form {
			.el-form-item {
				margin-right: 15px;
				margin-bottom: 10px;
				.el-input,
				.el-select {
					width: 180px;
				}
				.el-date-editor {
					.el-range-separator {
						padding: 0;
					}
					.el-range-input {
						width: 35%;
					}
				}
			}
			.kw {
				.el-select .el-input {
					width: 100px;
				}
				.el-select {
					width: 110px;
				}
				.input-with-select {
					vertical-align: middle;
					width: 320px;
				}
				.input-with-select .el-input-group__prepend {
					background-color: #fff;
				}
			}
			.store {
				width: 350px;
				.el-input__inner {
					width: 230px;
				}
			}
		}
	}
	.header-tab {
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-bottom: 2px solid #dcdfe6;
		height: 40px;
		line-height: 40px;
		margin-bottom: 20px;
		.left-tab {
			& > span {
				cursor: pointer;
				margin-right: 30px;
			}
			.select-tab {
				color: #409eff;
				border-bottom: 2px solid #409eff;
			}
			.el-badge {
				.el-badge__content.is-fixed {
					top: 7px;
					right: 5px;
				}
				.el-badge__content {
					height: 20px;
				}
			}
		}
	}
	.el-divider--horizontal {
		margin: 0 0 10px;
	}
	.header-count {
		margin-bottom: 20px;
		display: flex;
		justify-content: space-between;
		.selected {
			margin-right: 20px;
			padding: 0 10px;
			flex: 1;
			background-color: #e6f7ff;
			border: 1px solid #a3dcff;
			font-size: 12px;
			align-items: center;
			display: flex;
			i {
				color: #409eff;
				margin-right: 10px;
				font-size: 14px;
			}
			span {
				color: #409eff;
				font-size: 14px;
				margin-right: 10px;
			}
		}
	}
	.caozuo {
		.item {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}
	}
	.price {
		.item {
			min-width: 200px;
			display: flex;
			span {
				&:first-child {
					min-width: 100px;
					text-align: right;
				}
				&:last-child {
					min-width: 100px;
					text-align: left;
				}
			}
		}
	}
	.link {
		margin-bottom: 50px;
		height: 80px;
		display: flex;
		align-items: center;
		& > span {
			font-size: 16px;

			&:nth-child(2) {
				margin-right: 10px;
				border-radius: 2px;
				padding: 2px 10px;
				background-color: #fafafa;
				text-decoration: underline;
			}
		}
	}
	.prepay {
		.list {
			.item {
				margin: 5px 0;
			}
		}
	}
	.el-dialog__body {
		padding-top: 10px;
	}
	.kami {
		.member-name {
			display: flex;
			align-items: center;

			margin-bottom: 10px;
			font-size: 18px;
			font-weight: 600;
			.item {
				margin-right: 100px;
				i {
					margin: 0 3px;
					font-size: 15px;
				}
			}
		}
		.list {
			min-height: 300px;
			max-height: 400px;
			overflow-y: scroll;
			font-size: 16px;
			.item {
				padding: 10px;
				border-radius: 5px;
				background-color: #f5f5f5;
				margin: 5px 0;
				p {
					padding: 5px 0;
				}
			}
			&::-webkit-scrollbar {
				width: 0;
				background-color: #ccc;
			}
		}
	}
	.after-sale-dialog {
		.el-form-item {
			margin-bottom: 0;
		}
	}
	.avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}
	.avatar-uploader .el-upload:hover {
		border-color: #409eff;
	}
	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 148px;
		height: 148px;
		line-height: 148px;
		text-align: center;
	}
	.avatar {
		width: 148px;
		height: 148px;
		display: block;
	}
}
</style>
