<template>
	<div>
		<el-dialog :visible.sync="parentVisible" title="公证流程" width="950px">
			<div class="header">
				<div class="order-meta">
					<div><b>订单号：</b>{{ notaryDetail.orderNo }} </div>
                    <div><b>公证状态：</b>{{ notaryText }}</div>
				</div>
				<div v-if="showGetNotaryLink" class="get-notary-link">
					<div class="get-notary-link-label">公证链接</div>
					<el-button size="small" type="primary" @click="onGetNotaryLink" :disabled="notaryDetail.member.isIdentityDateExpire"
						>获取</el-button
					>
				</div>
			</div>
			<div>
				<el-form
					ref="notaryResultForm"
					:model="notaryResultForm"
					:rules="notaryFormRules"
					label-width="100px"
				>
					<div class="title">客户信息</div>
					<div class="detail-item">
						<div class="detail-label">客户姓名</div>
						<div>{{ notaryDetail.member.memberName }}</div>

						<div class="detail-label">性别</div>
						<div>{{ notaryDetail.member.genderName }}</div>
					</div>
					<div class="detail-item">
						<div class="detail-label">户籍地</div>
						<div>{{ notaryDetail.member.identityAddress }}</div>
					</div>
					<div class="detail-item">
						<div class="detail-label">身份证号</div>
						<div>{{ notaryDetail.member.identityNoPlain }}</div>
					</div>
					<div class="detail-item">
						<div class="detail-label">身份证有效期</div>
						<div>
							{{ notaryDetail.member.minIdentityDate }} -
							{{ notaryDetail.member.maxIdentityDate }}
						</div>
						<el-tag
							v-if="notaryDetail.member.isIdentityDateExpire"
							size="small"
							type="danger"
							>身份证已过期</el-tag
						>
					</div>
					<div class="detail-item">
						<div class="detail-label">身份证正反面</div>
						<div class="idcard-image">
							<div class="idcard-image-tips">
								身份证要求正反面照片清晰且在有效期内、边角无缺失
							</div>
							<div class="idcard-image-list">
								<el-image
									class="idcard"
									:src="notaryDetail.member.identityFrontImageUrl"
									:preview-src-list="[
										notaryDetail.member.identityFrontImageUrl,
									]"
									fit="contain"
								>
									<div slot="error" class="image-slot">
										<i class="el-icon-picture-outline"></i>
									</div>
								</el-image>
								<el-image
									class="idcard"
									:src="notaryDetail.member.identityBackImageUrl"
									:preview-src-list="[notaryDetail.member.identityBackImageUrl]"
									fit="contain"
								>
									<div slot="error" class="image-slot">
										<i class="el-icon-picture-outline"></i>
									</div>
								</el-image>
							</div>
						</div>
					</div>

					<div class="detail-item" v-if="isWaitingForAudit">
						<div class="detail-label">客户视频核验</div>
						<div class="detail-vedio">
							<div class="detail-vedio-tips">
								视频要求客户吐字清晰、无错字、全脸漏出、视频完整
							</div>
							<div class="detail-vedio-wrap">
                  <XgPlayer class="vedio-player" :url="notaryDetail.videoUrl" />
                  <div v-if="isWaitingForAudit" class="detail-vedio-intro">
                      我是{{ notaryDetail.member.memberName }}，我对《租赁服务合同》内容无异议，我自愿向凤凰公证处申请办理赋强公证，并知悉其法律意义和后果。
                  </div>
              </div>
						</div>
					</div>

					<div class="detail-item detail-signed-file-list-item" v-if="isWaitingForAudit">
						<div class="detail-label">已签署附件列表</div>
						<div class="detail-signed-file-list">
							<div
								v-for="file in notaryDetail.signedFileList"
								:key="file.fileName"
							>
								<div>{{ file.fileName }}</div>
								<el-button
									@click="
										() => {
											openSignedFile(file);
										}
									"
									size="small"
									type="primary"
									>查看</el-button
								>
							</div>
						</div>
					</div>

					<div class="audit-result" v-if="isWaitingForAudit">
						<div class="audit-result-title">审批结果</div>
						<div class="audit-result-content">
							<el-form-item prop="result">
								<el-radio-group v-model="notaryResultForm.result">
									<el-radio
										v-for="result in notaryResult"
										:key="result.value"
										:label="result.value"
                                        class="result-radio"
										>
                                        <span>{{ result.name.label }}</span>
                                        <span class="result-radio-tips">{{ result.name.tips }}</span> 
                                    </el-radio
									>
								</el-radio-group>
							</el-form-item>
						</div>
					</div>

					<div class="update-idcard" v-if="showUpdateId">
						<div class="update-idcard-title">
							<span class="required">*</span>上传身份证正反面
						</div>
						<div class="update-idcard-form">
							<FileUpload
								class="idcard-uploader"
								:name.sync="notaryResultForm.updateIdCard.front"
								>身份证人像面</FileUpload
							>
							<FileUpload
								class="idcard-uploader"
								:name.sync="notaryResultForm.updateIdCard.back"
								>身份证国徽面</FileUpload
							>
							<el-button
								
								@click="onUpdateIdcard"
								:disabled="updateIdDisabled"
								size="small"
								type="primary"
								>身份证更新</el-button
							>
						</div>
					</div>

					<el-form-item label="取消原因" prop="reason" v-if="showCancelReason">
						<el-input
							size="small"
							placeholder="请输入取消原因"
							v-model="notaryResultForm.reason"
						></el-input>
					</el-form-item>
				</el-form>
			</div>
			<div slot="footer" class="footer">
				<el-button
					@click="onCancelProcess"
					v-if="isBeforeWaitingForAudit"
					size="small"
					type="primary"
					>取消公证流程</el-button
				>
				<el-button
					@click="onSubmit"
					v-if="isWaitingForAudit"
					size="small"
					type="primary"
					>提交</el-button
				>
				<el-button size="small" @click="close">关闭</el-button>
			</div>
		</el-dialog>
		<el-dialog
			:visible.sync="cancelProcess.visible"
			title="取消公证流程后无法再次发起公证，请谨慎操作！"
			width="500px"
		>
			<el-form
				ref="cancelProcessForm"
				:model="cancelProcess"
				label-width="80px"
				:rules="rules"
			>
				<el-form-item label="取消原因" prop="reason">
					<el-input
						size="small"
						placeholder="请输入取消原因"
						v-model="cancelProcess.reason"
					></el-input>
				</el-form-item>
			</el-form>

			<div slot="footer" class="footer">
				<el-button @click="onConfirmCancelProcess" size="small" type="primary"
					>确认取消</el-button
				>
				<el-button size="small" @click="onCloseCancel">关闭</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import FileUpload from "@/components/file-upload";
import XgPlayer from "@/components/xgplayer";
import {
	enumNotaryStatus,
	notaryStatusToTextMap,
	enumNotaryResult,
	notaryResult,
} from "@/enum/dict.js";
import {
	getNotaryDetail,
	idcaedOCR,
	applyNotary,
	cancelNotary,
    passNotary,
	passNotaryAndModify,
	retryNotary,
} from "./api";

export default {
	components: { FileUpload, XgPlayer },
	props: {
		visible: {
			type: Boolean,
			defaultValue: false,
		},
		orderNo: {
			type: String,
			defaultValue: null,
		},
		notaryStatus: {
			type: Number,
			defaultValue: null,
		},
	},
	data() {
		return {
			notaryDetail: {
				notaryPhxStatus: null,
				notaryPhxStatusName: "",
				orderNo: "",
				member: {
                    id: "",
					genderName: "",
					identityAddress: "",
					identityBackImageUrl: "",
					identityFrontImageUrl: "",
					identityNoPlain: "",
					isIdentityDateExpire: false,
					maxIdentityDate: "",
					memberName: "",
					minIdentityDate: "",
				},
				videoUrl: "",
				signedFileList: [],
			},
			cancelProcess: {
				visible: false,
				reason: "",
			},
			rules: {
				reason: [{ required: true, message: "请输入取消原因" }],
			},
			notaryResult,
			notaryResultForm: {
				updateIdCard: {
					front: null,
					back: null,
				},
				result: enumNotaryResult.PASSED,
				reason: "",
			},
			notaryFormRules: {
				reason: [{ required: true, message: "请输入取消原因" }],
			},
		};
	},
	computed: {
		parentVisible: {
			get() {
				return this.visible;
			},
			set(value) {
				this.$emit("update:visible", value);
			},
		},
		// 处于待人工审核状态
		isWaitingForAudit() {
			return (
				this.notaryDetail.notaryPhxStatus === enumNotaryStatus.WAITING_FOR_AUDIT
			);
		},
		// 处于待人工审核之前的状态
		isBeforeWaitingForAudit() {
			return [
				enumNotaryStatus.WAITING_FOR_LINK,
				enumNotaryStatus.WAITING_FOR_SIGN,
			].includes(this.notaryDetail.notaryPhxStatus);
		},
		notaryText() {
			return notaryStatusToTextMap.get(this.notaryDetail.notaryPhxStatus);
		},
		showGetNotaryLink() {
			return [
				enumNotaryStatus.WAITING_FOR_LINK,
				enumNotaryStatus.WAITING_FOR_SIGN,
				enumNotaryStatus.CANCELED,
			].includes(this.notaryDetail.notaryPhxStatus);
		},
		showUpdateId() {
			return (
				this.notaryDetail.member.isIdentityDateExpire ||
				this.notaryResultForm.result === enumNotaryResult.MODIFY_AND_PASSED
			);
		},
		updateIdDisabled() {
			return (
				!this.notaryResultForm.updateIdCard.front ||
				!this.notaryResultForm.updateIdCard.back
			);
		},
		showCancelReason() {
			return [
				enumNotaryResult.CANCEL_AND_RENOTARY,
				enumNotaryResult.CANCEL,
			].includes(this.notaryResultForm.result);
		},
	},
	watch: {
		visible(newValue) {
			if (newValue) {
				this.getNotaryDetail();
			} else {
                this.resetUpdateIdcardForm();
                this.$refs.notaryResultForm.resetFields();
            }
		},
	},
	methods: {
		async getNotaryDetail() {
			if (!this.orderNo) {
				return;
			}

			const response = await getNotaryDetail({
				orderNo: this.orderNo,
			});

			this.$set(this, "notaryDetail", response);
		},
		async onGetNotaryLink() {
			const response = await applyNotary({
				orderNo: this.notaryDetail.orderNo,
			});

			navigator.clipboard.writeText(response);

			this.$message({
				message: "复制成功",
				type: "success",
			});

			this.getNotaryDetail();
		},
		async onUpdateIdcard() {
			await idcaedOCR({
                id: this.notaryDetail.member.id,
				identityFrontImage: this.notaryResultForm.updateIdCard.front,
				identityBackImage: this.notaryResultForm.updateIdCard.back,
			});

			this.getNotaryDetail();
			this.resetUpdateIdcardForm();
		},
		resetUpdateIdcardForm() {
			this.notaryResultForm.updateIdCard.front = null;
			this.notaryResultForm.updateIdCard.back = null;
		},
		onCancelProcess() {
			this.cancelProcess.visible = true;
		},
		onCloseCancel() {
			this.cancelProcess.visible = false;
		},
		async onConfirmCancelProcess() {
			const valid = await this.$refs.cancelProcessForm.validate();

			if (!valid) {
				return;
			}

			await cancelNotary({
				orderNo: this.notaryDetail.orderNo,
				reviewRemark: this.cancelProcess.reason,
			});

            this.cancelProcess.visible = false;
            this.$refs.cancelProcessForm.resetFields();

			this.close();
			this.$emit("finish");
		},
		close() {
			this.$emit("update:visible", false);
		},
		openSignedFile(file) {
			window.open(file.fileUrl, "_blank");
		},
		async pass() {
			await passNotary({
				orderNo: this.notaryDetail.orderNo,
			});

			this.close();
			this.$emit("finish");
		},
		async modifyAndPass() {
			await passNotaryAndModify({
				orderNo: this.notaryDetail.orderNo,
				identityFrontImage: this.notaryResultForm.updateIdCard.front,
				identityBackImage: this.notaryResultForm.updateIdCard.back,
			});

			this.close();
			this.$emit("finish");
		},
		async cancelAndRetry() {
			await retryNotary({
				orderNo: this.notaryDetail.orderNo,
				reviewRemark: this.notaryResultForm.reason,
			});

			this.getNotaryDetail();
		},
		async cancel() {
			await this.$confirm(
				"取消公证流程后无法再次发起公证，请谨慎操作！",
				"提示",
				{
					confirmButtonText: "确认取消",
					cancelButtonText: "取消",
					type: "warning",
				}
			);

			await cancelNotary({
				orderNo: this.notaryDetail.orderNo,
				reviewRemark: values.reason,
			});
		},
		async onSubmit() {
			const valid = await this.$refs.notaryResultForm.validate();

			if (!valid) {
				return;
			}

			switch (this.notaryResultForm.result) {
				case enumNotaryResult.PASSED:
					await this.pass();
					break;
				case enumNotaryResult.MODIFY_AND_PASSED:
					await this.modifyAndPass();
					break;
				case enumNotaryResult.CANCEL_AND_RENOTARY:
					await this.cancelAndRetry();
					break;
				case enumNotaryResult.CANCEL:
					await this.cancel();
				default:
			}

			this.resetUpdateIdcardForm();
			this.$refs.notaryResultForm.resetFields();
		},
	},
};
</script>

<style lang="scss" scoped>
.header {
	margin-bottom: 15px;
	border-bottom: 1px solid #d5d5d5;

	.order-meta {
        display: flex;
        gap: 15px;
		margin-bottom: 15px;
	}

	.get-notary-link {
		display: flex;
		align-items: center;
		gap: 10px;
		margin-bottom: 15px;

		.get-notary-link-label {
			font-weight: bold;
		}
	}
}

.title {
	font-size: 18px;
	margin-bottom: 15px;
}

.detail-item {
	display: flex;
	margin-top: 15px;
	gap: 10px;
}

.detail-label {
	width: 100px;
	text-align: right;
	font-weight: bold;
}

.idcard-image {
	.idcard-image-tips {
		color: #f56c6c;
	}

	.idcard-image-list {
		display: flex;
		gap: 10px;
		margin-top: 15px;
		.idcard {
			width: 250px;
			height: 150px;

			/deep/ .image-slot {
				display: flex;
				justify-content: center;
				align-items: center;
				width: 100%;
				height: 100%;
				background: #f5f7fa;
				color: #909399;
				font-size: 30px;
			}
		}
	}
}

.update-idcard {
	margin-top: 20px;

	.update-idcard-title {
		font-size: 18px;
		margin-bottom: 15px;

		.required {
			color: #f56c6c;
		}
	}

	.update-idcard-form {
		display: flex;
		align-items: flex-end;
		margin-left: 110px;
		gap: 10px;

		.idcard-uploader {
			/deep/ .el-upload {
				display: flex;
				align-items: center;
				justify-content: center;
				width: 250px;
				height: 150px;
			}
		}
	}
}

.detail-vedio {
	.detail-vedio-tips {
		color: #f56c6c;
		margin-bottom: 10px;
	}

    .detail-vedio-wrap {
        display: flex;
        align-items: center;
        gap: 20px;

        .detail-vedio-intro {
            width: 300px
        }
    }

	.vedio-player {
		width: 300px !important;
		height: 200px !important;
        flex-shrink: 0;
	}
}

.detail-signed-file-list-item {
    margin-top: 25px;
}

.detail-signed-file-list {
	display: flex;
	gap: 20px;
    margin-top: 35px;

	& > div {
		display: flex;
		align-items: center;
		gap: 10px;

		& > div {
			font-weight: bold;
		}
	}
}

.audit-result {
	margin-top: 20px;

	.audit-result-title {
		font-size: 20px;
		color: #409eff;
		margin-bottom: 15px;
	}

    /deep/ {
        .el-radio {
            margin-right: 50px;
        }
    }

    .result-radio {
    position: relative;
    .result-radio-tips {
        position: absolute;
        bottom: -100%;
        left: 24px;
        color: #f56c6c;
        font-size: 12px;
    }
}
}

.footer {
	display: flex;
	justify-content: flex-end;
	gap: 10px;
}
</style>
